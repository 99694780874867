.accuracy-table .table-column {
  flex: 1;
  min-width: 80px;
  display: flex;
  justify-content: center !important;
  padding: 16px !important;
  background-color: #fff;
  font-size: 0.8em;
  font-weight: 400;
}

.accuracy-table .table-header.table-column {
  background-color: #f5f5f5 !important;
  font-weight: 500;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.accuracy-table .table-column.table-first-column {
  min-width: 340px !important;
  justify-content: left !important;
  position: sticky !important;
  z-index: 99;
  left: 0;
  border-right: 1px solid #ccc;
  border-left: unset !important;
}

.accuracy-table .child-table-cell  {
  padding: unset !important;
}

.accuracy-table thead.MuiTableHead-root {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.accuracy-table .parent-header {
  font-weight: 500;
  font-size: 1em;
}

.superadmin-page-filter {
  margin: 15px 0px;
}

.superadmin-page-filter .MuiFormHelperText-root.Mui-error  {
  top: 28px;
}