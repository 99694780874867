.sa-action-section {
    text-align: end;
    margin-top : 0.5em;
 }
 
 .sa-dia-title {
     background-color: #ececec;
 }
 
 .table-format td, .table-format th {
     border: 1px solid #dddddd;
     text-align: left;
     padding: 8px;
 }
 
 .toggle-button.toggle-selected {
     background: var(--hti-magenta) !important;
     color: #fff !important;
 }
 
 .doc-stuck-table-headers {
     display: flex;
     justify-content: space-between;
     align-items: flex-end;
 }

 .mui-buttons-custom-filled {
    background-color: #b3b3b3 !important;
   
}

.doc-stuck-icon-text {
    display: flex;
    align-items: flex-end;
}

.doc-stuck-text-field {
    display: flex;
    flex-direction: column;
}

.sa-cmnt-dialog {
    margin: 20px;
}
.sa-buttons {
    color : var(--hti-magenta) !important;
}
.sa-bg-highlight {
    padding: 5px;
    background-color: #c1e1eb;
}
.mui-buttons-custom {
    color : var(--hti-magenta) !important;
    border : 1px solid var(--hti-magenta) !important;
}
.mui-buttons-custom-disabled{
    color : var(--hti-magenta) !important;
    border : 1px solid var(--hti-magenta) !important;
    opacity : 0.5 !important;
}
.mui-buttons-custom-filled {
    background-color: #b3b3b3 !important;
   
}