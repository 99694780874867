.doc-stuck-table td, .doc-stuck-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.doc-stuck-table .row-selection-checkbox:checked {
  color: var(--hti-magenta)!important;
  background-color: var(--hti-magenta)!important;
  padding: 6px!important;

}

.doc-stuck-table {
    width: 100%;
}

.doc-stuck-table-link:hover {
  text-decoration: underline;
}

.doc-stuck-table-container .no-data-content {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px 0px;
  font-weight: 500;
}