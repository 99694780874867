.loc-text-icon {
    align-items: center;
    padding: 0px 5px 0px;
    display: grid;
    grid-template-columns: 90% auto;
    justify-content: space-between;
}
.loc-text-icon svg{
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
}
.search-div {
    width: 97%;
    margin: 1px auto auto;
    display: flex;
    align-items: center;
    transition : all 0.3s ease-out;
    transform: translateX(-0%);
}
.search-div-hide{
    width: 97%;
    margin: 0px auto auto;
    display: flex;
    align-items: center;
    transition : all 0.3s ease-out;
    transform: translateX(0%);
}
.searchbar-text {
    background-color: white;
    border-style: none !important;
    border: 1px solid rgba(141, 141, 141, 0.7);
}
.acoordion_cutom{
    padding: 0px !important;
    min-height: 0px !important;

}
.acoordion_cutom .MuiAccordionSummary-content, .acoordion_cutom .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
}
.accordion{
    padding-top: 3px;
}
.accordion_container{
    margin: 0px 0px !important;
    border-radius: 0px;
    box-shadow: none;
}
.accordion_details{
    padding: 5px 0px 10px 5px !important;
}