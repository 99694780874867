.basic-info {
    flex-direction: column;
}
  
  .basic-info .title {
    text-align: left;
    margin-bottom: 10px;
    font-size: 16px;
    color: #30445D;
    margin-left: 16px;
  }
  
  .basic-info-list {
    flex-direction: column;
    text-align: left;
    padding-left: 16px;
  }
 .each-user-item{
   margin-bottom: 10%;
 }
  .userprofile-divider {
    width: 360px;
    background-color: #aa1572 !important;
  }
  
  .status-radio {
    margin-left: 26px;
  }
  
  .admin-info {
    flex-direction: column;
    background: #f2f2f2;
    padding-top: 106px;
    min-height: 100vh;
  }
  
  .admin-info .title {
    margin-bottom: 65px;
    color: #30445D;
  }
  
  .admin-info .heademail {
    color: #e0e0e0;
  }
  
  .admin-info .details {
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
  }

  .locationsbox{
    max-height: 250px;
    overflow: scroll;
  }

  .userprofilepage .MuiTypography-body1 {
    font-weight: unset;
  }
  
  .MuiListItemText-root.info-label, .info-label {
    font-size: 16px;
    color: #4f4f4f;
    font-weight: 300 !important;
  }
  
  .MuiListItemText-root.info-detail {
    font-size: 18px;
    color: #4f4f4f;
    font-weight: 400 !important;
  }
  
  .admin-info .adminemail {
    color: #aa1572;
    width: 100%;
    height: 40px;
  }
  
  .basic-info-list li {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .MuiCheckbox-colorPrimary {
    color: #aa1572 !important;
  }
  
  .user-details {
    display: flex;
  }
  
  .user-details .titlebox {
    margin-bottom: 50px; 
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .user-details-left{ 
      width: 70%;
    }
    
    .user-details-right {
      width: 30%;
    }
  }

  /* Extra large devices (large monitors, 1600px and up) */
  @media only screen and (min-width: 1600px) {
    .user-details-left{ 
      width: 50%;
    }
    
    .user-details-right {
      width: 50%;
    }
  }