.custom-table {
    font-size: 0.9rem;
    margin-top: 15px;
}

.table-container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.table-header {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 40px;
    background: #f2f2f2;
}

.table-body {
    height: auto;
    max-height: 350px;
    overflow: auto;
}

.table-body::-webkit-scrollbar { 
    display: none;
}

.table-body .tb-row {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 40px;
}

.table-body .tb-row:last-child {
    border: unset;
}

.table-header .tb-col,
.table-body .tb-col,
.main-header .tb-col {
    padding: 5px;
    /* min-width: 60px; */
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-header .tb-col {
    justify-content: flex-start;
    padding: 10px 15px;
    color: var(--hti-title);
    font-weight: 500;
    align-items: flex-start;
}

.table-header .tb-grouped-col.col-right, .main-header .tb-grouped-col.col-right {
    /* margin-right: 7px; */
}

.center-col {
    background-color: #fff0f1;
}

.table-header .tb-col-1,
.table-body .tb-col-1 {
    /* min-width: 150px; */
    flex: 2;
    justify-content: flex-start;
    text-align: left;

}

.main-header {
    display: flex;
    justify-content: space-between;
    background: rgba(113, 24, 101, 0.1);
    height: 50px;
}


.tb-grouped-col {
    display: flex;
}

.tb-grouped-col.col-left {
    width: 30%;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.tb-grouped-col.col-right {
    width: 70%;
}

.table-navigations {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
}

.table-navigations .prev, .table-navigations .next, .table-navigations .center-title {
    margin: 0px 15px 10px 15px;
    cursor: pointer;
    color: var(--hti-title);
    font-weight: 500;
    display: flex;
    align-items: center;
}

.table-navigations .prev.disabled, .table-navigations .next.disabled {
    cursor: unset;
    opacity: 0.4;
    color: #000;
}