.header-container {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-end;
}

.radio-btn-group {
    display: flex;
}

.radio-btn-item.radio-btn-selected {
    background: var(--hti-magenta);
    color: #fff;
    border: 1px solid var(--hti-magenta);
}

.radio-btn-item:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.radio-btn-item:last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.radio-btn-item {
    padding: 7px 15px;
    display: flex;
    background: #fff;
    margin: 0px 0.5px;
    border: 1px solid var(--hti-magenta);
    color: var(--hti-magenta);
}

.radio-btn-item:hover {
    cursor: pointer;
}


.header-left-portion {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    margin-right: 15px;
    min-width: fit-content;
}

.header-right-portion {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

/* .report-title {
    font-size: 1.5rem;
    font-weight: 500;
} */

.report-container {
    margin: 70px 24px 10px;
}

.report-chart-header {
    display: flex;
    padding: 0px 20px;
    font-weight: 400;
    color: var(--hti-title);
    justify-content: space-between;
    align-items: end;
}

.chart-subtitle {
    font-size: 0.8em;
    color: rgb(104, 103, 103);
    margin-top: 7px;
}

.report-chart-header .icon-text {
    margin-right: 1.5em;
}

.report-chart-header .icon-text svg {
    width: 0.8em;
    height: 0.8em;
}

.report-chart-title {
    font-size: 1.1em;
    color: var(--hti-title);
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    text-align: initial;
    line-height: 17px;
}

.location-table .paper-title .report-chart-header {
    align-items: baseline;
    justify-content: flex-start;
}

.rp-print-section{
    display: flex;
    align-items: center;
    cursor: pointer;
    color : #aa1572;
}
.rp-print-section > span {
    display: flex;
    align-items: center;
}

.export-popover-menu {
    background: #AF486B !important;
    padding: 10px;
} 
.export-popover-menu-item {
    color: #fff;
    padding: 10px 5px;
    border-bottom: 1px solid #ccc;
}

.export-popover-menu-item:hover {
    cursor: pointer;
}

.export-popover-menu-item:last-child {
    border: unset;
    padding-bottom: unset;
}

.export-report-link{
    color : #aa1572 !important;
    text-decoration: none !important;
    margin-right: 10px;
}

.rp-print-button {
    margin-right: 20px;
}

.export-popover-menu-item .button-as-link {
    color: #fff !important;
    text-decoration: none !important;
}

.report-page-loader {
    position: absolute;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.01);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paper-style {
    width: 46%;
}

.doc-view {
    height: 356px!important;
    overflow-y: scroll;
}

@media print {
    
    .rp-filter {
        display: none;
    }

    .rp-print-header {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(96.29deg, var(--hti-title) -25.11%, var(--hti-pink) 98.84%);
      padding: 0 0.2cm 0 0.2cm;

    }
    
    .rp-print-section {
        display: none;
    }

    .rp-print-name{
        color : grey;
       
    }
    .rp-flex {
        justify-content: inherit;
        padding: 0.2cm;
       
    }
    .rp-curr-month-disp {
        margin-left: 1cm;
        margin-right: 1cm;
    }

    .report-print-header-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .rp-metrics-section {
        display: flex;
        flex-direction: row !important;
        flex-flow: unset !important;
        width: 100% !important;
    }

    .rp-each-metric {
        flex: 1 !important;
    }

    .rp-other-info {
        display: none;
    }
    
    .monthly-report-container {
        display: block !important;
    }

    .monthly-report-right-portion {
        margin-left: 10px;
        width: 100%;
    }
    
    .monthly-report-left-portion {
        border-right: unset !important;
        width: 100% !important;
        padding: 1cm !important;
    }
/* 
    .monthly-chart {
        margin-top: -5.5cm !important;
    }
     */
}

@media screen {
    .rp-logo {
        display: none;
    }
    .rp-print-title{
        display: none;
    }
    .rp-print-header {
        display: none;
    }
    .rp-print-name {
        display: none;
    }

    .report-print-content {
        display: none;
    }
}