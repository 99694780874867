.radio-btn-group {
    display: flex;
}

.radio-btn-item.radio-btn-selected {
    background: var(--hti-magenta);
    color: #fff;
    border: 1px solid var(--hti-magenta);
}

.radio-btn-item:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.radio-btn-item:last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.radio-btn-item {
    padding: 7px 15px;
    display: flex;
    background: #fff;
    margin: 0px 0.5px;
    border: 1px solid var(--hti-magenta);
    color: var(--hti-magenta);
    width: max-content;
}

.radio-btn-item:hover {
    cursor: pointer;
}

.radio-btn-item.radio-btn-disabled {
    cursor: auto !important;
    opacity: 0.8;
}