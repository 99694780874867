.page-num-align{
    display: flex;
    align-items: center;
    float: right;
  
}
.page-box {
  border-radius: 3px;
   width : 25px;
    border : 1px solid #dddddd;
   
}