.location-area-chart,
.location-bar-chart {
  border-bottom: 1px solid #ccc;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.location-hourly-charts {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.location-title {
    display: flex;
    padding: 10px 15px;
    background: rgba(113, 24, 101, 0.1);
    color: var(--hti-title);
    font-size: 1.1rem;
}

.location-search {
    display: flex;
    padding: 5px 15px;
    background: #F2F2F2;
}

.location-chart-list {
  height: 580px;
  overflow-y: auto;
}

.location-bar-chart {
  padding-bottom: 10px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.chart-header .total-count {
  display: flex;
  justify-content: space-between;
  width: 15%;
}
