.report-filters {
  display: flex;
  align-items: end;
}

.report-filters .apply-button{
  margin: unset;
  margin-left: 10px;
  height: 28px !important;
}

.report-filters .MuiTextField-root{
  margin: unset;
}