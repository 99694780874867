.daily-report-left-portion {
    display: flex;
    flex-direction: column;
    width: 68%;
    /* border-right: 1px solid #711865; */
    padding-right: 10px;
}

.daily-report-right-portion {
    width: 30%;
    margin: 0px 15px;
}

.daily-report-container {
    display: flex;
    width: 100%;
}

.daily-report-container .paper-style {
    width: 100%;
}
.daily-report-container .doc-view {
    height: 640px !important;
}