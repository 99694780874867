#doc-cat-box {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
}
.category-section{
    min-height: 40vh;
}
.margin-first {
    margin-left: 7px;

}

.margin-all {
    margin-left: 23px;
}
.doc-count-disp {
    font-size: 1em;
    font-weight: 500;
    color: darkslategrey;
    margin : 0px;
    padding : 5px;
}
.count-circle {
    border: 1px solid grey;
    border-radius: 18px;
    height: 30px;
    width: 30px;
}
.custom-chip-doc {

height : 22px;
width : 37px;
background: #BDBDBD;
border: 1px solid #BDBDBD;
border-radius: 33px;
}

.check-box-color {
    color : var(--hti-magenta) !important;
    padding: 6px !important;
}
.check-box-disable {
    opacity: 0.5 !important;
}