.subCategory-table .table-column {
    flex: 1;
    text-align: center;
    justify-content: center;
}  
.subCategory-table .table-column:first-child {
    justify-content: flex-start;
}  

.subCategory-table thead {
    position: sticky;
    top: 0;
}
.subCategory-table thead .table-column {
    text-align: center;
}

