.login-page {
  /*background-color: rgba(48, 68, 93, 0.42);*/
background : radial-gradient(63.28% 63.28% at 50% 61.94%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(48, 68, 93, 0.42);
min-height: 100vh;

}
.topBox {
  /*height: 20% !important;*/
  min-height: 30vh;
  left: 0px;
  right: 0px;
  top: 0px;
 
   
 
  background-image: linear-gradient(to right, var(--hti-pink) , var(--hti-title) );

}

.logoGrp {
  margin-top: 4%;
}

.textDiv {
  margin-top: 1.2em;
  display: flex;
  justify-content: center;
}

.docu {
  /*  font-family: Ubuntu;
    font-style: normal;*/
  font-weight: 500;
  font-size: 1.8em;
  line-height: 2em;
  text-align: center;
  color: #ffffff;
}
.route {
  /* font-family: Ubuntu;
    font-style: normal;*/
  font-weight: 300;
  font-size: 1.8em;
  line-height: 2em;
  text-align: center;
  color: #ffffff;
  padding-left: 7px;
}

/*.loginBox {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-top: 354px;
  bottom: 0px;
  height: 439px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}*/
.bottom-box {
  min-height: 64vh;
  
}
.cardStyle {
  border-radius: 10px !important;
  margin: 5vh auto 0px auto;
 
  width: 400px;
  background : rgba(255, 255, 255, 0.95) !important;
}

.user-input-username {
  margin-top: 1.75em;
  text-align: center;
}

.user-input-pwd {
  margin-top: 6%;
  text-align: center;
}
.user-input-login {
  margin-top: 6%;
  display: flex;
  justify-content: center;
  margin: 1.5em 3.5em 0.5em 0em;
 
}


.forgot-pwd {
  font-size: 0.8em;
line-height: 1em;
cursor: pointer;
color: #4F4F4F;
}
.text-box {
  width: 300px;
  height: 37px !important;
  margin: -12px auto auto 0px;
  font-size : 1em;
  background: #ffffff;
  border: 1px solid rgba(141, 141, 141, 0.7);
  box-sizing: border-box;
  border-radius: 4px;
}
.text-box-error {
  width: 300px;
 
  margin: -12px auto auto 0px;
  font-size : 1em;
  background: #ffffff;
  border: 1px solid red !important;
  box-sizing: border-box;
  border-radius: 4px;
}

.text-style {
  text-align: justify;
  margin-left: 50px;
  font-size: 1.2em;
  line-height: 21px;
  margin: 0px 0px 5px 50px;
}

.top-color {
  height: 7px;
  background-image: linear-gradient(var(--hti-pink),var(--hti-title));
  border-radius: 30px 30px 0px 0px;
}

.button-style {
  width: 150px;
  height: 36px;
  border-style: none;
  float: left;
 
  background-color: var(--hti-magenta);
  border-radius: 4px;
  cursor: pointer;
}
.button-style:hover{
  background: var(--hti-gradient);
}
.button-style:focus{
  outline: none !important;
}

.button-text {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.2em;
  margin-top : 8px;
  text-align: center;
  color: #ffffff;
}


.logo-transparent {
  mix-blend-mode: multiply;
  text-align: center;   
}
.error-msg {
  color : red;
  font-size: 1em;
  padding-left: 5px;
  margin : 0px;
 
}
.success-msg {
  color : green;
  font-size: 1em;
  padding-left: 5px;
  margin : 0px;
  align-items: center;
}
.msg-div {
  display: flex;

  align-items: center;
  margin : 15px 0px 0px 45px;
}
@supports not (mix-blend-mode: multiply) {
  .logo-transparent::after {
    opacity: 0.5;
  }
}
/*=====================Laptop big================*/
@media only screen and (min-width: 1440px){
  .topBox {
    min-height: 28vh;
  }
  .bottom-box {
    min-height: 50vh;
  }
 
  .logoGrp {
    margin-top: 5%;
  }
}