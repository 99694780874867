.chart-style {
    min-height: 40vh !important;
     width : 99% !important;
     font-size : 10px !important;
 }
 .chip-inactive {
     margin : 15px 5px 0px 5px !important;     
     color : var(--hti-magenta) !important;
 }
 .chip-active {
     margin : 15px 5px 0px 5px !important;    
     color : white !important;
     background-color: var(--hti-magenta) !important;
 }
 .custom-chip {
     border: 1px solid;
     margin-top: 15px;
     height: 23px;
     border-radius: 14px;
     color: var(--hti-magenta) ;
     margin : 15px 10px 0px 10px;
     cursor : pointer;
 }
 .custom-active-chip {
     border: 1px solid;
     margin-top: 15px;
     height: 23px;
     border-radius: 14px;
     color : var(--hti-magenta) ;
     background-color: var(--hti-magenta) ;
     margin : 15px 10px 0px 10px;
     cursor : pointer;
 }
 .inactive-chip {
    font-size: 0.8em;    
    color : var(--hti-magenta) ;
 
 }
 .active-chip {
    font-size: 0.8em;    
    color : white;
    
 }

 .overlay-scroll:hover {
    overflow-y: overlay !important;
 }
 
 .doc-view{
    height: 356px !important;
    overflow-y: scroll;
 }

 .mini-hyperlink {
    font-size: 0.9em;
    text-align: left;
    margin: 0px;
    cursor: pointer;
    color: #4f4f4f;
    margin-left : 3px;    
  }

.mini-hyperlink:hover{
    text-decoration: underline;
}
.mini-hyperlink:hover .tooltip-text{
        visibility: visible;
        opacity: 0.8;
}
.chart-info-msg {
        position: absolute;
        margin-left: 20%;
}

.echarts-for-react > div:last-child:has(div.tooltip-headers) {
    border-radius: 5px !important;
    border: 1px solid #c6c6c6 !important;
}
     
.tooltip-headers{
    background-color: #eceff1;
    color: black;
    font-size: 12px;
    border-bottom: 1px solid #c6c6c6;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.tooltip-body{
    text-align: left;
    color: black;
    padding: 4px;
    font-weight:400;
    margin-left: 5px;
    border-radius: 5px;

 }
.tooltip-body-container{
    padding:  8px 15px 8px 8px;

 }
.text-value{
    color: black;
    font-weight: 600;
    margin-left: 5px;
 }  

 /*=====================Laptop big================*/
@media only screen and (min-width: 1440px){
     .chart-style {
         min-height: 35vh !important;
     }
 }
 /*=====================Tablet====================*/
@media only screen and (max-width: 900px){
     .chart-style {
         min-height: 30vh !important;
     }
     .flex-style-paper {
         display: grid;
         margin : 30px;
     }
     .chart-style {
         min-height: 23vh !important;
     }
 }
 
 
    