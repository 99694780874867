.upload-title {
   
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}
.title-bg {
    background-color: var(--hti-magenta);
    
}
.title-text{
    margin : 0px;
    color :  var(--hti-magenta);
    font-size: 1.1em;
}
.dialog-box {
    width : 565px;
    height : 700px;
}
.view-files-hide {
   visibility: hidden;
   height: 182px;
   margin: 10px 0px 10px 0px;
  
}
.view-files-alter{
    height : max-content;
    margin: 10px 0px 10px 0px;
}
.view-files{
    visibility: visible;
    height: 182px;
    margin: 10px 0px 10px 0px;
   
}
.files-box {
    max-height: 400px;
    overflow-y: scroll;
}

.box-size {
  
    width : 500px;
}
.div-center {
    display: flex;
    justify-content: center;
    margin: 0.5em 0 0.5em 0;
    align-items: center;
}
.file-name {
    padding : 3px;
    margin : 0px;

}

.drop-file-area {
    background-color: lightgrey;
}
.manual-select {
   margin-top : 7px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.title-icon {
    padding: 0px 5px 0px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height : 43px;
}
.title-icon:hover{
    background: #F1E7EF;
    cursor: pointer;
}
.add-file-disable {
    cursor: auto;
    padding: 0px 5px 0px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height : 43px;
    opacity: 0.5;
}
.MuiSelect-select {
    max-height: 30px !important;
    overflow-y: auto !important;
}
.file-msg{
    margin : 0 auto;
}
.loader-space {
    margin-right: 5px;
    color : var(--hti-magenta) !important;
}