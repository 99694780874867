.summary {
  background-color: lightgray !important;
}
.accordView {
  display: grid !important;
}

.title-box {
  height: 43px;
  width : 16vw;
  background: rgba(113, 24, 101, 0.1);
}
.main-content {
  margin-top: 60px;
  display: flex;
}

.docmgmt-right-content {
  padding: 15px;
}
.sortSection {
  display: flex;
  align-items: center;
}
.tableStyle {
  max-height: 490px !important;
}
.docLayout {
  display: flex;
  flex-flow: wrap;
  margin: 20px;
}
.filterSection {
  width: 95%;
  margin-right: 10px;
  height: 100%;
}
.tag-chip {
  text-align: left;
  margin: 10px 0px 10px 0px;
  display: flex;
  height: 20px;
 
  flex-flow: wrap;
}

.suggest-result {
  font-size: 0.95em;
  cursor: pointer;
  text-align: left;
  padding: 5px;
  margin : 5px;
}
.suggest-result:hover {
  background: rgba(113, 24, 101, 0.1);
}
.block-area-doc {
  opacity : 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.suggest-box {
  position: absolute;
  z-index: 200;
  background-color: white;
  width: 300px;
  max-height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 1px 1px 1px lightgray;
  
 
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  color : black !important;
  overflow: auto;
}

.thBorder {
  background-color: #f5f5f5;
  border-bottom: 2px solid #dddddd;
}

 .table-row-color {
  background-color:  rgba(113, 24, 101, 0.05);
  cursor: pointer;
} 
.table-row {
  cursor: pointer;
}
.table-row-blue {
  cursor: pointer;
  background-color:  #2d9cdb1a;
}
.table-row-red {
  cursor: pointer;
  background-color: #ff6a6a33;
}
.edit-icon-row {
  
 visibility: hidden;
}
.table-row:hover {
  background: linear-gradient(0deg, rgba(245, 245, 245, 0.6), rgba(245, 245, 245, 0.6));
 
}
.table-row:hover .edit-icon-row {
 
  visibility: visible;
}
.table-width {
  width : 85vw;
  padding: 0px 10px 0px 10px;
  
}
.table-width-less {
  width:60vw;
  padding: 0px 10px 0px 10px;
 
}
.left-plane-width {
  width : 16vw;
 border-right : 1px solid rgba(113, 24, 101, 0.7);
 min-height: 100vh;
 
}


.right-plane-width {
  width: 24vw;
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
}

.table-cont{
  max-height: 75vh !important;
  overflow: auto !important;
  font-size: 0.5em !important;
}
.patient-icon-show {
  visibility: visible !important;
}

.patient-icon-hide {
  visibility: hidden !important;
}

.text-button-upload {
  cursor: pointer;
  color: var(--hti-title);
  padding: 0px 10px 0px 10px;
  margin: 7px 15px 0px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 35px;
}
.text-button-upload:hover {
  background-color: #F1E7EF;
}
.flag-array-ellipsis {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.vertical-mini-divider{
  border: 0.5px solid #a2a0a0;
  height: 13px;
  background-color: #a2a0a0;
  margin: 0 5px;
  opacity: 0.5;
}

.remove-report-icon {
  padding-left: 20px !important;
}