.enc-filters {
    display: flex;
    align-items: flex-end;
    flex-flow: wrap;
}
.enc-filter-fields {
    margin-right: 0.8em;
    display: flex;
    
}
.enc-table{
   max-height: 40vh !important;
}
.enc-data-section {
    height: 40vh;
    text-align: center;
}