.upload-title {
   
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}
.title-bg {
    background-color: var(--hti-magenta);
    
}
.title-text{
    margin : 0px;
    color :  var(--hti-magenta);
    font-size: 1.1em;
}
.dialog-box {
    width : 565px;
    height : 700px;
}
.view-files-hide {
   visibility: hidden;
   height: 182px;
   margin: 10px 0px 10px 0px;
  
}
.view-files-alter{
    height : max-content;
    margin: 10px 0px 10px 0px;
}
.view-files{
    visibility: visible;
    height: 182px;
    margin: 10px 0px 10px 0px;
   
}
.files-box {
    max-height: 400px;
    overflow-y: scroll;
}

.box-size {
  
    width : 500px;
}
.text-button {
    cursor: pointer;
    color : var(--hti-title);
   
    margin-right : 28px;
}
.div-center {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.file-name {
    padding : 3px;
    margin : 0px;

}

.drop-file-area {
    
   
    background-color: lightgrey;
}
.manual-select {
   
    display: flex;
    justify-content: space-between;

}
.title-icon {
  
    padding: 0px 5px 0px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height : 43px;
  
   
}
.title-icon:hover{
    background: #F1E7EF;
    cursor: pointer;
}

.MuiOutlinedInput-input {
    width: 243px !important;
    height: 37px !important;
    padding: 0 0 0 5px !important;

}

.field-control {
    display: flex;
    margin-bottom: 12px;
}

.field-control label {
    margin-bottom: 4px;
}

.field-control > div {
    margin-right: 30px;
}

.radio-container {
    margin-right: 53px !important;
    color: rgba(79, 79, 79, 1);
}

.radio-fields {
    display: flex;
}

.text-area {
    margin-top: 5px;
    height: 100px !important;
    width: 500px;
}

.div-center {
    margin-top: 20px;
}

.textareabox {
    margin-bottom: 20px !important;
    margin-top: 15px !important;
}

.button-container {
    margin-top: 43px;
    text-align: center;
}
.MuiDialogContent-root form{
    margin-left: 10px;
    margin-top: 10px;
}
.loc-loader {
    color : var(--hti-magenta) !important;
    margin : 15% auto;
}
.loc-title{
    margin-right : 0.8em;
}
.loc-action-text {
    padding: 0.5em;
    margin: 0px;
    color: var(--hti-magenta);
    border-radius: 4px;
}
.loc-action-text:hover {
    background-color: #F1E7EF;
}