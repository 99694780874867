

.fg-each-chip:last-child .fg-comma{
    visibility : hidden;
}
.fg-section {
    display : flex;
    align-items: baseline;
    margin : 0.5em 0.8em 0 0;
    max-width: 50%;
}

.fg-each-chip {
    background-color:#7118651a;
    padding: 2px 7px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin : 2px;
    min-width: 0;
}
.fg-chip-close {
    color : grey;
    padding-left: 2px;
}
.fg-array-ellipsis {
    max-width: 290px;
    /*overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;*/
    text-align: left;
    margin-left: 5px;
}
.fg-wrap {
    text-align: left;
    margin-left: 5px;
    display: flex;
    white-space: nowrap;
    flex-flow: wrap;
}

.flags-selection-title {
    color: var(--hti-magenta);
    font-size: 13px;
    font-weight: 500;
}
