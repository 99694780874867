.table-format
td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .table-format
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .title-sa{
    text-align: left;
    margin-bottom: 10px;
    font-size: 1.2em;
    text-transform: uppercase;
   
  }
  .mini-text-sa {
    text-align: left;
    
  }
  .sub-title-sa{
    text-align: left;
    margin-bottom: 5px;
    font-size: 1.2em;
    text-transform: uppercase;
  }
  .title-sa-main{
    text-align: left;
    color : var(--hti-purple);
    margin-bottom: 10px;
    font-size: 1.4em;
    background-color: #c7c5c5;
  }