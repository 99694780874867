.fg-popup {
    border: 1px solid var(--hti-magenta);
    width: 13em;
    position: absolute;
    z-index: 100;
    background-color: white;
    margin-top : 3px;
    box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.05);
    border-top: 3px solid var(--hti-magenta);  
}
.fg-data {
    height: 24em;
    overflow: scroll;
    padding: 0em 0em 0em 1.3em;
}
.fg-select-all {
    padding: 0 0.7em;
}
.fg-list-section {
    margin-top: -12px;
}
.fg-count:hover{
    background-color: var(--hti-hover);
}

.fg-count{
    border-radius: 10px;
    border: 1px solid #aa1572;
    height: 1.15em;
    margin-left: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fg-count-number{
    margin-left: 4px;
   
}
.fg-flag-icon {
    display: flex;
    cursor: pointer;
    color : var(--hti-magenta);
    border-radius: 3px;
    align-items: flex-end;
    margin-left: -11px;
}
.fg-check-icon{
    text-align: right;
    margin-right: 5px;
    visibility: visible;
}
.fg-flag-italise{
    font-style: italic;
}