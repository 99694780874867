.audit-log {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-top: 60px;
}
.audit-export-link{
       display: flex;
       align-items: end;
       cursor: pointer;
       color : var(--hti-magenta);
   
}
.button-as-link{
      border: none;
      background: none;
      color: var(--hti-magenta);
      cursor: pointer;
  
}
.button-as-link:hover{
   text-decoration: underline;
 }