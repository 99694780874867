.left-overview {
  width : 24%;
  min-height: 100vh;
  background-image: linear-gradient(  rgba(113, 24, 101, 0.1),white);
 
}
.perform-metrics-grid{
  width : 56%;
  border-left : 1px solid rgba(113, 24, 101, 0.7);
  border-right : 1px solid rgba(113, 24, 101, 0.7);
  
}
.right-view-grid {
  width : 20%;
}
.flex-style-overview {
  display: flex;
  margin-top: 60px;
}

.flex-style-paper {
  display: flex;
  justify-content: space-around;
}  

.paper-style {
  width: 46%; 
}

.paper-title {
  height : 4em;
  background-color: rgba(113, 24, 101, 0.1);
}

.flex-justify-unique {
  display: flex;
  justify-content: center;
}
.logs-in-tablet {
  display: none;
}
/*======================================Tablet===========================*/
@media only screen and (max-width: 900px){
  .right-view-grid {
    display: none;
  }
  .perform-metrics-grid{
    width : 60%;
    border-right : none;
  }
  .left-overview {
   
    width : 40%;
  }
  .paper-style {
    width: 100%;
    
    margin : 0px 0px 20px 0px;
  
  }
  .logs-in-tablet{
    display: block;
  }
}