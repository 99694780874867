
.title-box-preview{
    height: 43px;
    width : 100%;
     background: rgba(113, 24, 101, 0.1);
     display: flex;
     justify-content: space-between;
     align-items: center;
}
.outer-div {
    width : 375px;
    height : 500px;
    overflow : hidden;
}
.inner-div {
    width : 100%;
    height : 100%;
    background-image: url('../../../utils/images/doc_sample.png');
    background-position: center;
    background-size: cover;
    transition: transform ease 0.3s;
   

}
.inner-div:hover{
    transition: scale(1.2);
}

.view-nlp-button {
    margin-right: 10px;
    display: flex;
    align-items: center;
    color: var(--hti-magenta);
}

.view-nlp-button:hover {
    cursor: pointer;
    text-decoration: underline;
}



