.ehr-popup-header {
    color: white;
    display: flex;
    flex-flow: wrap;
}
.ehr-pt-each-detail{
    display: flex;
    flex-flow: wrap;
    margin-bottom: 0.5em;
}
.ehr-pt-details {
   border: 1px solid grey;
   height: 55vh;
   padding: 2em 0 0 2em;
   overflow: scroll;
   
}
.ehr-pt-label{
   width: 7vw;
   color: grey;
   word-break: break-all;
   margin-right: 1em;
}
.ehr-pt-value {
    word-break: break-all;
    width: 15vw;
}
.popup-spinner{
    color : #aa1572 !important;
    margin: 20% 0 0 40%;
    z-index: 1;
    position: absolute;
}
.ehr-popup-vertical-divider{
    border: 0.3px solid white;
    height: 18px;
    background-color: white;
    margin: 0 5px;
    opacity: 0.5;
}
.ehr-table-row-color{
    background-color: #71186538;
    cursor: pointer;
}

.title-bg {
    background-color: var(--hti-magenta);
}

.upload-title {
    display: flex;
    align-items: center;
    justify-content: space-between!important;
}

.div-center {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 20px;
}