.rp-metrics-section {
    display : flex;
    flex-flow : wrap;
    min-height: 25vh;
}
.rp-left-section {
    border-right: 1px solid #711865;
    min-height: 100vh;
}
.rp-other-info {
    min-height: 70vh;
    margin: 25px 20px 20px 10px;
}

.rp-metrics-numbers {
    font-size: 1.8em;
    margin-right: 5px;
}

.rp-mini-text{
    font-size: 0.9em;
}
.rp-flex-baseline {
    display: flex;
    align-items: baseline;
}
.rp-under-line{
    height: 3px;
    background-color: #aa1572;
    margin-top: 5px;
    transition : all 0.3s ease-out;
  }
  .rp-under-line-show{
    margin-top: 5px;
    opacity : 1;
    transform : translateX(-0%);
  }
  .rp-under-line-hide{
    margin-top: 5px;
    opacity : 0;
    transform : translateX(100%);
  }
  .rp-text-active {
    color : #4f4f4f;
    font-size: 1.15em;
    cursor: pointer;
    font-weight: 500;
}
.rp-flex-btwn {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}

@media print {
    .flex-style {
        margin: 0.2cm;
    }
    .rp-left-section{
         width : auto;
         min-height: fit-content;
         border: none;
         margin-bottom: -2.5cm;
         margin-left: 0.5cm;
    }
    .rp-other-info {
        display: none;
    }
    .rp-print-other-info {
        display: flex;
        justify-content: space-between;
    }
    
}