.extract-area {
  width: 50%;
  min-height: 100vh;
  border-right: 1px solid rgba(113, 24, 101, 0.7);
}
.block-area {
  width: 50%;
  min-height: 100vh;
  border-right: 1px solid rgba(113, 24, 101, 0.7);
  cursor: not-allowed;
}
.block-events {
  pointer-events: none;
}
.flex-margin-review {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-area {
  width: 50%;
}

.border-below {
  border-bottom: 1px solid #bdbdbd;
}
.border-red{
  border-bottom: 1px solid #FF6A6A;
}
.border-blue {
  border-bottom: 1px solid #2D9CDB;
}

.value-font {
    
  margin : 0px;
  margin-top: 5px;
 
}
.label-font {
  margin : 0px;
  margin-top: 10px;
  font-weight: 300;
  white-space: nowrap;
}
.loader {
  margin : 0.2em 0.5em 0em 0.5em;
  color : var(--hti-magenta) !important;
}
.hide-loader {
  visibility: hidden;
}
.show-loader {
  visibility: visible;
}
/*==================preview CSS=======================*/
.img-preview {
  position: relative;
  border : 1px solid #e0e0e0;
  width : 47vw;
  margin : 10px auto; 
  overflow-y: scroll; 
  overflow-x: hidden;
}
.img-preview-pdf {
  position: relative;
  border : 1px solid #e0e0e0;
  width : 47vw;
  margin : 10px auto; 
 
}
.title-box-preview {
  height: 43px;
  width: 100%;
  background: rgba(113, 24, 101, 0.1);
  display: flex;
  justify-content: space-between;
}
.outer-div {
  width: 375px;
  height: 500px;
  overflow: hidden;
}
.inner-div {
  width: 100%;
  height: 100%;
  background-image: url("../../utils/images/doc_sample.png");
  background-position: center;
  background-size: cover;
  transition: transform ease 0.3s;
}
.inner-div:hover {
  transition: scale(1.2);
}

.highlight-ocr {
  position: absolute;
  visibility: hidden;
  background-color: yellow;
  mix-blend-mode: multiply;
  opacity: 0.5;
}

.patient-creation-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.back-to-document-details {
  font-weight: 500;
  color: var(--hti-magenta);
}
.back-to-document-details:hover {
  cursor: pointer;
  text-decoration: underline;
}
.apply-button {
  min-width: 80px;
}

.title-box-preview{
  height: 43px;
  width : 100%;
  background: rgba(113, 24, 101, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-nlp-button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: var(--hti-magenta);
}

.view-nlp-button:hover {
  cursor: pointer;
  text-decoration: underline;
}